* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Roboto,sans-serif;
  font-size: 14px;
}

.bg-red { fill: #f12020; }
.bg-blue { fill: #1e4cbd; }
.bg-yellow { fill: #e5d922; }
.bg-darkred{ fill: #660000; }

.react-transform-component{
  height: 100vh !important;
  display: flex !important;
  align-content: center;
  width: 100vw !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}